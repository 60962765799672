export interface ExpressionSetCardProps {
  name: string;
  description: string;
  expression_count: number;
  expression_count_loading?: boolean;
}

export function ExpressionSetCard({
  name,
  description,
  expression_count,
  expression_count_loading,
}: ExpressionSetCardProps) {
  return (
    <article className="content-card">
      <h2 className="content-row text-title margin-small">{name}</h2>
      <span className="content-row text-meta margin-small">
        {expression_count_loading
          ? "loading"
          : `${expression_count} expressions(s)`}
      </span>
      <p className="content-row text-details margin-paragraph">{description}</p>
    </article>
  );
}
