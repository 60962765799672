import { useContext } from "react";
import { AppPath, AppRouting } from "../../model/routing";

export interface SettingsSectionLinkProps {
  text: string;
  iconUrl?: string;
  page: AppPath;
}

export function SettingsSectionLink({
  text,
  iconUrl,
  page,
}: SettingsSectionLinkProps) {
  const { setRoute } = useContext(AppRouting);
  return (
    <li onClick={() => setRoute({ path: page })}>
      <div className="content-card">
        <div className="content-row">
          {iconUrl && <img src={iconUrl} width="24" height="24" alt="" />}
          <h2 className="text-title padding-small">{text}</h2>
        </div>
      </div>
    </li>
  );
}
