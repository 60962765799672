import { useContext } from "react";
import { AppPath, AppRouting } from "../../model/routing";

export function HomeView() {
  const { setRoute } = useContext(AppRouting);
  return (
    <div className="page-with-padding content-text scroll">
      <h1 className="text-title margin-title">Study Card Tool</h1>
      <p className="text-details margin-paragraph">
        A handy tool for vocabulary practice through repetition. Go to
        "settings" to add some words and you'll be ready to practice.
      </p>
      <nav>
        <ol className="navigation">
          <li
            className="navigation-li"
            onClick={() => setRoute({ path: AppPath.CreateCards })}
          >
            <div className="navigation-item">Create study cards</div>
          </li>
          <li
            className="navigation-li"
            onClick={() => setRoute({ path: AppPath.ExpressionSets })}
          >
            <div className="navigation-item">Practice</div>
          </li>
        </ol>
      </nav>
      <p className="text-details margin-paragraph">
        Newly created cards will go onto the "daily" deck. As you remember and
        forget word definitions they will move to weekly, monthly, yearly or
        back towards the daily deck.
      </p>
      <p className="text-details margin-paragraph">
        Words that are remembered beyond the highest level will be removed, and
        words that are so poorly remembered that you find yourself attempting to
        create cards for them more than once will have duplicate cards.
      </p>
      <h2 className="text-title margin-title">Privacy</h2>
      <p className="text-details margin-paragraph">
        There is no data collection by the app. The app makes requests to
        Wiktionary during card creation, their privacy policy can be found{" "}
        <a
          className="link"
          target="_blank"
          rel="noreferrer"
          href="https://foundation.wikimedia.org/wiki/Privacy_policy"
        >
          on this link
        </a>
        .
      </p>
      <h2 className="text-title margin-title">Licensing</h2>
      <p className="text-details margin-paragraph">
        Human-readable summary:{" "}
        <strong>
          You are free to use the app for any purposes. You are free to modify
          and redistribute the app as long as you grant your users access to the
          source-code, content and assets you modify and redistribute under the
          same terms listed below
        </strong>
        .
      </p>
      <ul className="item-list">
        <li className="margin-small">
          <a className="link" target="_blank" href="/icons/icons-license.txt">
            User interface icons
          </a>
        </li>
        <li className="margin-small">
          <a
            className="link"
            target="_blank"
            rel="noreferrer"
            href="https://creativecommons.org/licenses/by-sa/3.0/"
          >
            Study cards text
          </a>
        </li>
        <li className="margin-small">
          <a
            className="link"
            target="_blank"
            href="/static/js/full-license-manifest.txt"
          >
            3rd-party javascript libraries
          </a>
        </li>
        <li className="margin-small">
          <a
            className="link"
            target="_blank"
            rel="noreferrer"
            href="https://www.gnu.org/licenses/agpl-3.0.en.html"
          >
            Study Card Tool original source code
          </a>
        </li>
      </ul>
      <h2 className="text-title margin-title">Support the app</h2>
      <p className="text-details margin-paragraph">
        This app would not exist if it was not for Wiktionary and the Wikimedia
        foundation. If you want to support anyone consider donating to the{" "}
        <a
          className="link"
          target="_blank"
          rel="noreferrer"
          href="https://wikimediafoundation.org/"
        >
          Wikimedia Foundation
        </a>
        . This app or its author is in no way affiliated with the Wikimedia
        Foundation, it just makes use of its data.
      </p>
      <h2 className="text-title margin-title">Contributing</h2>
      <p className="text-details margin-paragraph">
        Code, comments and feedback, design improvements are very much welcome.
        To participate join{" "}
        <a
          className="link"
          target="_blank"
          rel="noreferrer"
          href="https://git.studycardtool.org/studycardtool/app"
        >
          the official repository
        </a>
        .
      </p>
    </div>
  );
}
