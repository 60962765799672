import { useContext } from "react";
import { ExpressionSetInfo } from "../../components";
import {
  useExpressionSetById,
  useQueryExpressionSetId,
  useExpressionsByExpressionSetId,
} from "../../hooks";
import { IndexedExpressionSet, ItemNotFoundError } from "../../model";
import { AppPath, AppRouting } from "../../model/routing";
import { ErrorView } from "../ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

export function ExpressionSetDetailsView() {
  const expression_set_id = useQueryExpressionSetId();
  const expression_set = useExpressionSetById(expression_set_id);
  const expressions = useExpressionsByExpressionSetId(expression_set_id);

  if (!expression_set || !expressions) return <LoadingView />;
  if (expression_set === ItemNotFoundError) {
    return <ErrorView message="Expression set not found" />;
  }

  return (
    <ExpressionSetDetailsViewImpl
      expression_set={expression_set}
      expression_count={expressions.length}
    />
  );
}

interface ExpressionSetDetailsViewImplProps {
  expression_set: IndexedExpressionSet;
  expression_count: number;
}

function ExpressionSetDetailsViewImpl({
  expression_set,
  expression_count,
}: ExpressionSetDetailsViewImplProps) {
  const { setRoute } = useContext(AppRouting);
  if (!expression_count) {
    return (
      <div className="page-with-padding scroll">
        <ExpressionSetInfo
          id={expression_set.id!}
          name={expression_set.name}
          description={expression_set.description}
          expression_count={expression_count}
        />
        <p className="text-details">No expressions left in this set.</p>
      </div>
    );
  }

  return (
    <div className="page-with-bottom-navigation">
      <section className="padding-small scroll">
        <ExpressionSetInfo
          id={expression_set.id!}
          name={expression_set.name}
          description={expression_set.description}
          expression_count={expression_count}
        />
      </section>
      <section className="navigation-bottom">
        <div
          className="navigation-item bottom text-navigation grow"
          onClick={() =>
            setRoute({
              path: AppPath.ExpressionSetsPractice,
              options: { expression_set_id: expression_set.id },
            })
          }
        >
          <span>Practice this set</span>
        </div>
      </section>
    </div>
  );
}
