import { useCallback, useContext } from "react";
import {
  useQueryExpressionIdFilters,
  useQueryExpressionSetId,
} from "../../hooks";
import { assignExpressionToSet } from "../../model";
import { AppRouting } from "../../model/routing";

// TODO ensure that the destination expression set exists

export interface DemoteExpressionButtonProps {
  expression_id: number;
}

export function DemoteExpressionButton({
  expression_id,
}: DemoteExpressionButtonProps) {
  const { route, setRoute } = useContext(AppRouting);
  const expression_set_id = useQueryExpressionSetId();
  const expression_id_filters = useQueryExpressionIdFilters();
  const handleClick = useCallback(() => {
    if (expression_set_id === 1) {
      setRoute({
        path: route.path,
        options: {
          ...route.options,
          expression_id_filters: expression_id_filters.concat(expression_id),
        },
      });
    } else {
      assignExpressionToSet({
        expression_id,
        expression_set_id: Math.max(1, expression_set_id - 1),
      });
    }
  }, [
    expression_id,
    expression_id_filters,
    expression_set_id,
    route,
    setRoute,
  ]);
  return (
    <button
      className="navigation-item demote-button bottom text-navigation grow"
      onClick={handleClick}
    >
      <img src="/icons/x.svg" width="24" height="24" alt="" />
      <span>I forgot</span>
    </button>
  );
}
