import { useContext } from "react";
import { ExpressionSetCard } from "../../components";
import { useExpressionsByExpressionSetId } from "../../hooks";
import { IndexedExpressionSet } from "../../model";
import { AppPath, AppRouting } from "../../model/routing";

export function ExpressionSetLink({
  id,
  description,
  name,
}: IndexedExpressionSet) {
  const { setRoute } = useContext(AppRouting);
  const expressions = useExpressionsByExpressionSetId(id!);

  return (
    <div
      onClick={() =>
        setRoute({
          path: AppPath.ExpressionSetsDetails,
          options: { expression_set_id: id },
        })
      }
    >
      <ExpressionSetCard
        name={name}
        description={description}
        expression_count={expressions?.length || 0}
        expression_count_loading={expressions === undefined}
      />
    </div>
  );
}
