import { createContext } from "react";

export enum AppPath {
  Home = "/",
  ExpressionSets = "expression-sets",
  // ExpressionSetsSettings = "expression-sets/settings",
  ExpressionSetsPractice = "expression-sets/practice",
  ExpressionSetsDetails = "expression-sets/details",
  Settings = "settings",
  CardsList = "settings/cards",
  CardView = "settings/view-card",
  CreateCards = "settings/create-cards",
}

export interface RouteOptions {
  // Used in cards view
  expression_id?: number;

  // Used in practice view
  expression_set_id?: number;
  expression_id_filters?: number[];
}

export interface AppRoute {
  path: AppPath;
  options?: RouteOptions;
}

interface AppRoutingType {
  route: AppRoute;
  setRoute(value: AppRoute): void;
}

export const AppRouting = createContext<AppRoutingType>({
  route: { path: AppPath.Home },
  setRoute(_value: AppRoute) {},
});
