import { AppPath } from "../../model/routing";
import { NavigationItem } from "./NavigationItem";

export function Navigation() {
  return (
    <nav>
      <ol className="navigation">
        <NavigationItem
          text="home"
          iconUrl="/icons/home.svg"
          page={AppPath.Home}
        />
        <NavigationItem
          text="practice"
          iconUrl="/icons/calendar.svg"
          page={AppPath.ExpressionSets}
        />
        <NavigationItem
          text="settings"
          iconUrl="/icons/settings.svg"
          page={AppPath.Settings}
        />
      </ol>
    </nav>
  );
}
