import { useLiveQuery } from "dexie-react-hooks";
import { database } from "../model";

// TODO there may be a case here for reporting errors where relations exist
//      but some referenced item does not exist in its table, maybe the
//      return value should be { entries[], errors[] }
export function useCategoriesByExpressionId(expression_id: number) {
  return useLiveQuery(() => {
    return database.expression_to_category
      .where({ expression_id })
      .toArray()
      .then((relationships) => {
        const category_ids = relationships.map((item) => item.category_id);
        return database.categories.where("id").anyOf(category_ids).toArray();
      });
  }, [expression_id]);
}
