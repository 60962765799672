import { SettingsSectionLink } from "../../components/SettingsSection";
import { AppPath } from "../../model/routing";

export function SettingsView() {
  return (
    <div className="page-with-padding content-list scroll">
      <ul className="content-list">
        <SettingsSectionLink
          text="Create cards"
          page={AppPath.CreateCards}
          iconUrl="/icons/file-plus.svg"
        />
        <SettingsSectionLink
          text="Cards list"
          page={AppPath.CardsList}
          iconUrl="/icons/list.svg"
        />
      </ul>
    </div>
  );
}
