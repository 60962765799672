export interface ExpressionSetInfoProps {
  id: number;
  name: string;
  description: string;
  expression_count: number;
}

export function ExpressionSetInfo({
  id,
  name,
  description,
  expression_count,
}: ExpressionSetInfoProps) {
  return (
    <section>
      <h2 className="content-row margin-small">
        <span className="text-title grow">{name}</span>
        {/*
        <div
          className="icon-button"
          onClick={() =>
            setRoute({
              path: AppPath.ExpressionSetsSettings,
              options: { expression_set_id: id },
            })
          }
        >
          <img
            src="/icons/settings.svg"
            width="24"
            height="24"
            alt="settings"
          />
        </div>
        */}
      </h2>
      <span className="content-row text-meta margin-small">
        {expression_count} expression(s)
      </span>
      <p className="content-row text-details margin-paragraph">{description}</p>
    </section>
  );
}
