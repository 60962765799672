import { useContext } from "react";
import { AppPath, AppRouting } from "../../model/routing";

export interface NavigationItemProps {
  text: string;
  iconUrl?: string;
  page: AppPath;
}

export function NavigationItem({ page, text, iconUrl }: NavigationItemProps) {
  const { route, setRoute } = useContext(AppRouting);

  let active = false;
  if (page === AppPath.Home && route.path === AppPath.Home) active = true;
  if (page !== AppPath.Home && route.path.startsWith(page)) active = true;

  return (
    <li className="navigation-li" onClick={() => setRoute({ path: page })}>
      <div className={active ? "navigation-item active" : "navigation-item"}>
        {iconUrl && <img src={iconUrl} width="24" height="24" alt="" />}
        <span className="text-navigation">{text}</span>
      </div>
    </li>
  );
}
