import { useCallback } from "react";
import { useQueryExpressionSetId } from "../../hooks";
import { assignExpressionToSet, database, removeExpression } from "../../model";

export interface PromoteExpressionButtonProps {
  expression_id: number;
}

// "Promote" action will destroy the card if it's learning level is already at top level
// TODO notify user of card deletion

export function PromoteExpressionButton({
  expression_id,
}: PromoteExpressionButtonProps) {
  const expression_set_id = useQueryExpressionSetId();
  const handleClick = useCallback(async () => {
    const existing_next_level = await database.expression_sets
      .where({ id: expression_set_id + 1 })
      .first();
    if (existing_next_level) {
      assignExpressionToSet({
        expression_id,
        expression_set_id: expression_set_id + 1,
      });
    } else {
      removeExpression(expression_id);
    }
  }, [expression_id, expression_set_id]);

  return (
    <button
      className="navigation-item promote-button bottom text-navigation grow"
      onClick={handleClick}
    >
      <img src="/icons/check.svg" width="24" height="24" alt="" />
      <span>I remembered</span>
    </button>
  );
}
