import { useAllExpressionSets } from "../../hooks";
import { ErrorView } from "../ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";
import { ExpressionSetLink } from "./ExpressionSetLink";

export function ExpressionSetListView() {
  const expression_sets = useAllExpressionSets();

  if (!expression_sets) return <LoadingView />;
  if (!expression_sets.length) {
    return <ErrorView message="No expression sets found" />;
  }

  return (
    <div className="page-with-padding content-list scroll">
      {expression_sets.map(({ id, name, description }) => (
        <ExpressionSetLink
          key={id}
          id={id}
          name={name}
          description={description}
        />
      ))}
    </div>
  );
}
