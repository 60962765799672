import React from "react";
import { Navigation, Page, WithRouting } from "./components";
import "./styles/globals.css";
import "./styles/components.css";
import "./styles/wiktionary.css";

function App() {
  return (
    <div className="page">
      <WithRouting>
        <header>
          <Navigation />
        </header>
        <main>
          <Page />
        </main>
      </WithRouting>
    </div>
  );
}

export default App;
