import { Expression } from "../../model";

export interface ExpressionDescriptionProps {
  expression: Expression;
}

export function ExpressionDescription({
  expression,
}: ExpressionDescriptionProps) {
  return <div dangerouslySetInnerHTML={{ __html: expression.description }} />;
}
