import { useState } from "react";
import { ExpressionCard } from "../../components";
import { ExpressionDescription } from "../../components/ExpressionDescription/ExpressionDescription";
import { IndexedExpression, IndexedCategory } from "../../model";
import { DemoteExpressionButton } from "./DemoteExpressionButton";
import { PromoteExpressionButton } from "./PromoteExpressionButton";

export interface ExpressionPracticeCardViewProps {
  expression: IndexedExpression;
  categories: IndexedCategory[];
}

// Handle internal state here
export function ExpressionPracticeCardView({
  expression,
  categories,
}: ExpressionPracticeCardViewProps) {
  const [revealed, setRevealed] = useState(false);
  return (
    <div className="page-with-bottom-navigation">
      <section className="padding-small scroll">
        <ExpressionCard
          prompt={expression.prompt}
          categories={categories.map((category) => category.name)}
          description={<ExpressionDescription expression={expression} />}
          show_description={revealed}
        />
      </section>
      <section className="navigation-bottom">
        {revealed ? (
          <>
            <DemoteExpressionButton expression_id={expression.id!} />
            <PromoteExpressionButton expression_id={expression.id!} />
          </>
        ) : (
          <button
            className="navigation-item bottom text-navigation grow"
            type="button"
            onClick={() => setRevealed(true)}
          >
            Tap here to reveal the full card
          </button>
        )}
      </section>
    </div>
  );
}
