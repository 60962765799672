import { useLiveQuery } from "dexie-react-hooks";
import { database } from "../model";

// TODO there may be a case here for reporting errors where relations exist
//      but some referenced item does not exist in its table, maybe the
//      return value should be { entries[], errors[] }
export function useExpressionsByExpressionSetId(expression_set_id: number) {
  return useLiveQuery(() => {
    return database.expression_to_expression_set
      .where({ expression_set_id })
      .toArray()
      .then((relationships) => {
        const expression_ids = relationships.map((item) => item.expression_id);
        return database.expressions.where("id").anyOf(expression_ids).toArray();
      });
  }, [expression_set_id]);
}
