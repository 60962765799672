import { PropsWithChildren, useState } from "react";
import { AppPath, AppRoute, AppRouting } from "../../model/routing";

interface WithRoutingProps {
  path?: AppPath;
}

export function WithRouting({
  children,
  path,
}: PropsWithChildren<WithRoutingProps>) {
  const [route, setRoute] = useState<AppRoute>({ path: path || AppPath.Home });
  return (
    <AppRouting.Provider value={{ route, setRoute }}>
      {children}
    </AppRouting.Provider>
  );
}
