export function LoadingView() {
  return (
    <div className="page-loading">
      <img
        className="loading-icon"
        src="/icons/rotate-clockwise.svg"
        width="64"
        height="64"
        alt=""
      />
    </div>
  );
}
