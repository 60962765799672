import { ExpressionCard } from "../../components";
import { ExpressionDescription } from "../../components/ExpressionDescription";
import { useExpressionById, useQueryExpressionId } from "../../hooks";
import { ItemNotFoundError } from "../../model";
import { ErrorView } from "../ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

export function ExpressionCardView() {
  const expression_id = useQueryExpressionId();
  const expression = useExpressionById(expression_id);

  if (expression === undefined) return <LoadingView />;
  if (expression === ItemNotFoundError)
    return <ErrorView message="Expression card not found" />;

  return (
    <div className="page-with-padding content-list scroll">
      <ExpressionCard
        prompt={expression.prompt}
        categories={[]}
        description={<ExpressionDescription expression={expression} />}
        show_description
      />
    </div>
  );
}
