export interface ErrorViewProps {
  message: string;
}

export function ErrorView({ message }: ErrorViewProps) {
  return (
    <div className="page-with-padding">
      <p className="text-meta">{message}</p>
    </div>
  );
}
