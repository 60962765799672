import { ReactNode } from "react";

export interface ExpressionCardProps {
  prompt: string;
  categories: string[];
  description: ReactNode;
  show_description?: boolean;
}

export function ExpressionCard({
  prompt,
  categories,
  description,
  show_description,
}: ExpressionCardProps) {
  return (
    <article className="content-card">
      <h2 className="content-row text-title margin-small">{prompt}</h2>
      <span className="content-row text-meta margin-small">
        {categories.join(", ")}
      </span>
      {show_description ? (
        <div className="content-row text-details margin-paragraph">
          {description}
        </div>
      ) : null}
    </article>
  );
}
