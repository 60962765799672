import { useContext, useMemo } from "react";
import { ExpressionCard } from "../../components";
import { useAllExpressions } from "../../hooks";
import { IndexedExpression } from "../../model";
import { AppPath, AppRouting } from "../../model/routing";
import { ErrorView } from "../ErrorView";
import { LoadingView } from "../LoadingView/LoadingView";

export function ExpressionCardListView() {
  const { setRoute } = useContext(AppRouting);
  const expressions = useAllExpressions();
  const expression_list = useMemo(
    () => (expressions || []).concat().sort(sort_function),
    [expressions]
  );

  if (!expressions) return <LoadingView />;
  if (!expressions.length) {
    return <ErrorView message="No expression cards yet" />;
  }

  return (
    <div className="page-with-padding content-list scroll">
      <ul className="content-list">
        {expression_list.map(({ prompt, id, description }) => (
          <li
            key={id}
            onClick={() =>
              setRoute({
                path: AppPath.CardView,
                options: { expression_id: id },
              })
            }
          >
            <ExpressionCard
              prompt={prompt}
              categories={[]}
              description={description}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

function sort_function(a: IndexedExpression, b: IndexedExpression) {
  if (a.prompt < b.prompt) return -1;
  if (a.prompt > b.prompt) return 1;
  return 0;
}
