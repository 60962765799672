import { useContext } from "react";
import { AppPath, AppRouting } from "../../model/routing";
import {
  ExpressionPracticeView,
  ExpressionSetDetailsView,
  ExpressionSetListView,
} from "../../views";
import { AddExpressionView } from "../../views/AddExpressionView";
import { ExpressionCardListView } from "../../views/ExpressionCardListView";
import { ExpressionCardView } from "../../views/ExpressionCardView";
import { HomeView } from "../../views/HomeView";
import { SettingsView } from "../../views/SettingsView";

export function Page() {
  const { route } = useContext(AppRouting);
  console.log("Route:", JSON.stringify(route));

  switch (route.path) {
    case AppPath.Home:
      return <HomeView />;
    case AppPath.ExpressionSets:
      return <ExpressionSetListView />;
    case AppPath.ExpressionSetsDetails:
      return <ExpressionSetDetailsView />;
    case AppPath.ExpressionSetsPractice:
      return <ExpressionPracticeView />;
    case AppPath.Settings:
      return <SettingsView />;
    case AppPath.CreateCards:
      return <AddExpressionView />;
    case AppPath.CardsList:
      return <ExpressionCardListView />;
    case AppPath.CardView:
      return <ExpressionCardView />;
  }
}
