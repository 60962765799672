import {
  Category,
  Expression,
  ExpressionSet,
  ExpressionToCategory,
  ExpressionToExpressionSet,
} from "./types";

const RawExpressionData: Expression[] = [];

const RawExpressionSetData: ExpressionSet[] = [
  ["daily", "New expressions and poorly remembered expressions"],
  ["weekly", "Expressions to be reviewed at the end of the week"],
  ["monthly", "Expressions to be reviewed at the end of the month"],
  ["yearly", "Expressions to be reviewed at the end of the year"],
  ["ancient", "Expressions here should be memorized by now"],
].map(([name, description]) => ({ name, description }));

// TODO replace with data source, data type or other category like wiktionary, species, math?
const RawCategories: Category[] = [
  "noun",
  "verb",
  "adjective",
  "adverb",
  "determiner",
  "article",
  "preposition",
  "conjunction",
  "proper noun",
  "letter",
  "character",
  "phrase",
  "proverb",
  "idiom",
  "symbol",
  "syllable",
  "numeral",
  "initialism",
  "interjection",
  "definitions",
  "pronoun",
  "particle",
  "predicative",
  "participle",
  "suffix",
].map((name) => ({ name, description: name }));

interface InitialData {
  // Tables
  categories: Category[];
  expressions: Expression[];
  expression_sets: ExpressionSet[];

  // Relationships
  expression_to_category: ExpressionToCategory[];
  expression_to_expression_set: ExpressionToExpressionSet[];
}

interface ParseRawDataArgs {
  raw_category_data: Category[];
  raw_expression_data: Expression[];
  raw_expression_set_data: ExpressionSet[];
}

export function parseRawData({
  raw_category_data,
  raw_expression_data,
  raw_expression_set_data,
}: ParseRawDataArgs): InitialData {
  const expression_to_category: ExpressionToCategory[] = [];
  const expression_to_expression_set: ExpressionToExpressionSet[] = [];

  return {
    categories: raw_category_data,
    expressions: raw_expression_data,
    expression_sets: raw_expression_set_data,
    expression_to_category,
    expression_to_expression_set,
  };
}

export const initialData = parseRawData({
  raw_category_data: RawCategories,
  raw_expression_data: RawExpressionData,
  raw_expression_set_data: RawExpressionSetData,
});
