import { initialData } from "./initial-data";
import Dexie, { Table } from "dexie";
import {
  Category,
  Expression,
  ExpressionSet,
  ExpressionToCategory,
  ExpressionToExpressionSet,
} from "./types";

type WithId<T> = T & { id?: number };

export type IndexedExpression = WithId<Expression>;
export type IndexedExpressionSet = WithId<ExpressionSet>;
export type IndexedCategory = WithId<Category>;

export const ItemNotFoundError = Symbol();

class Database extends Dexie {
  expressions!: Table<IndexedExpression, number>;
  expression_sets!: Table<IndexedExpressionSet, number>;
  categories!: Table<IndexedCategory, number>;
  expression_to_expression_set!: Table<ExpressionToExpressionSet>;
  expression_to_category!: Table<ExpressionToCategory>;

  constructor() {
    super("Database");
    this.version(1).stores({
      expressions: "++id, prompt, description",
      expression_sets: "++id, name, description",
      categories: "++id, name, description",
      expression_to_expression_set: "expression_id, expression_set_id",
      expression_to_category: "expression_id, category_id",
    });
  }
}

export const database = new Database();
database.on("populate", (transaction) => {
  const db = transaction as unknown as Database;
  const {
    expressions,
    expression_sets,
    categories,
    expression_to_expression_set,
    expression_to_category,
  } = initialData;
  db.expressions.bulkAdd(expressions);
  db.expression_sets.bulkAdd(expression_sets);
  db.categories.bulkAdd(categories);
  db.expression_to_expression_set.bulkAdd(expression_to_expression_set);
  db.expression_to_category.bulkAdd(expression_to_category);
});

//
// Trivial table operations
//

export async function addExpression(expression: Expression) {
  return await database.expressions.add(expression);
}

export async function addExpressionSet(expression_set: ExpressionSet) {
  return await database.expression_sets.add(expression_set);
}

export async function addCategory(category: Category) {
  return await database.categories.add(category);
}

//
// Deletion operations
//

export async function removeExpression(expression_id: number) {
  return await database.transaction(
    "rw",
    database.expressions,
    database.expression_to_category,
    database.expression_to_expression_set,
    () => {
      database.expressions.where({ id: expression_id }).delete();
      database.expression_to_category.where({ expression_id }).delete();
      database.expression_to_expression_set.where({ expression_id }).delete();
    }
  );
}

export async function removeCategory(category_id: number) {
  return await database.transaction(
    "rw",
    database.categories,
    database.expression_to_category,
    () => {
      database.categories.where({ id: category_id }).delete();
      database.expression_to_category.where({ category_id }).delete();
    }
  );
}

//
// Relationship operations
//

// (re)assigns an Expression to an expression set
export async function assignExpressionToSet({
  expression_id,
  expression_set_id,
}: ExpressionToExpressionSet) {
  return await database.transaction(
    "rw",
    database.expression_to_expression_set,
    () => {
      database.expression_to_expression_set
        .where("expression_id")
        .equals(expression_id)
        .delete();
      database.expression_to_expression_set.add({
        expression_id,
        expression_set_id,
      });
    }
  );
}

// creates an expression-category relationship, prevents duplication
export async function assignCategoryToExpression(
  relationship: ExpressionToCategory
) {
  const existing = await database.expression_to_category
    .where(relationship)
    .first();
  if (existing) return existing;

  return await database.expression_to_category.add(relationship);
}

export async function unassignCategoryToExpression(
  relationship: ExpressionToCategory
) {
  return await database.expression_to_category.where(relationship).delete();
}

//
// Complex utility function to add expression with its relationships function
//

export interface addExpressionWithRelationshipsParams {
  expression: Expression;
  expression_set_id: number;
  category_ids: number[];
}

export async function addExpressionWithRelationships({
  expression,
  expression_set_id,
  category_ids,
}: addExpressionWithRelationshipsParams) {
  return await database.transaction(
    "rw",
    database.expressions,
    database.categories,
    database.expression_to_expression_set,
    () => {
      database.expressions.add(expression).then((expression_id) => {
        database.expression_to_expression_set.add({
          expression_id,
          expression_set_id,
        });
        if (category_ids.length) {
          database.expression_to_category.bulkAdd(
            category_ids.map((category_id) => ({ expression_id, category_id }))
          );
        }
      });
    }
  );
}
