import { useLiveQuery } from "dexie-react-hooks";
import { database, ItemNotFoundError } from "../model";

export function useExpressionSetById(expression_set_id: number) {
  return useLiveQuery(
    () =>
      database.expression_sets
        .where({ id: expression_set_id })
        .toArray()
        .then((result) => {
          if (result.length === 0) return ItemNotFoundError;
          return result[0];
        }),
    [expression_set_id]
  );
}
